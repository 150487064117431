


























































import BaseCard from "@/components/Base/BaseCard.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import { loadFile } from "@/helpers/useLoader";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseDialog,
    },
})
export default class SharedProposalInfoGallery extends Vue {
    @Prop() proposal!: any;

    public showImageDetail: boolean = false;
    public selectedIndex: number = -1;

    get galleries() {
        if (!this.proposal) return [];

        const galleries = this.proposal.galleries;

        return galleries;
    }

    get images() {
        const images: any[] = [];
        this.galleries.map((gallery: any) => {
            const isImage0Exist: boolean = !!gallery.image_0_path;
            const isImage1Exist: boolean = !!gallery.image_1_path;
            const isImage2Exist: boolean = !!gallery.image_2_path;
            if (isImage0Exist) images.push(gallery.image_0_path);
            if (isImage1Exist) images.push(gallery.image_1_path);
            if (isImage2Exist) images.push(gallery.image_2_path);
        });

        return images;
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    selectImage(index: number) {
        this.selectedIndex = index;
        this.showImageDetail = true;
    }
}
