








































import { Component, Prop, Vue } from "vue-property-decorator";
import { toCurrency } from "@/helpers/Formatter";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseLink from "@/components/Base/BaseLink.vue";

@Component({
    components: {
        BaseAvatar,
        BaseLink,
    },
})
export default class SharedProposalInfoReceivedFundItem extends Vue {
    @Prop({ default: "pa-4" }) customClass!: string;
    @Prop() fund!: any;
    @Prop() index!: number;

    toCurrency(value: number) {
        return toCurrency(value);
    }
}
