















































































































































import BaseForm from "../../Base/BaseForm.vue";
import BaseDialog from "../../Base/BaseDialog.vue";
import BaseCard from "../../Base/BaseCard.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import SharedProposalInfoGallery from "./InfoGallery.vue";
import SharedProposalInfoProposerAndProposal from "./InfoProposerAndProposal.vue";
import SharedProposalInfoReceivedFund from "./InfoReceivedFund.vue";
import OthersDownloader from "@/components/Others/Downloader.vue";
import { loadFile } from "@/helpers/useLoader";
import { toDate } from "@/helpers/Formatter";

@Component({
    components: {
        SharedProposalInfoProposerAndProposal,
        SharedProposalInfoReceivedFund,
        SharedProposalInfoGallery,
        BaseCard,
        BaseDialog,
        BaseForm,
        OthersDownloader,
    },
})
export default class SharedProposalInfo extends Vue {
    @Prop() proposal!: any;
    @Prop() plan!: any;

    public isLoading: boolean = false;
    public isFormValid: boolean = false;
    public showDialog: boolean = false;
    public showDialogDetail: boolean = false;
    public showDialogDelete: boolean = false;
    public selectedReport: any = null;
    public forms: any[] = [
        {
            text: "Deskripsi",
            placeholder: "Foto kegiatan, kwitansi, dsb",
            value: "description",
            withLabel: true,
            required: true,
        },
        {
            text: "Gambar laporan / dokumen",
            placeholder: "Pilih gambar",
            value: "report",
            withLabel: true,
            type: "image",
            required: true,
        },
    ];
    public reportData: any = {};

    get reports() {
        return this.$store.state.reports.reports;
    }

    get isProposer() {
        const meRole = this.$store.getters["users/meRole"];

        return meRole === "PROPOSER";
    }

    get isOwner() {
        const proposerId = this.proposal.user.id;
        const me = this.$store.state.users.me;
        const isOwner = proposerId === me.id;

        return isOwner;
    }

    loadFile(path: string) {
        return loadFile(path);
    }

    toDate(date: any) {
        return toDate(date);
    }

    async createReport() {
        this.isLoading = true;

        const payload = {
            proposalId: this.proposal.id,
            report: this.reportData,
        };

        await this.$store.dispatch("reports/addReport", payload);

        setTimeout(() => {
            this.showDialog = false;
            this.isLoading = false;
        }, 500);
    }

    async getReports() {
        await this.$store.dispatch(
            "reports/getProposalReports",
            this.proposal.id
        );
    }

    async deleteReport() {
        this.isLoading = true;

        await this.$store.dispatch("reports/deleteReport", this.selectedReport);
        await this.getReports();

        setTimeout(() => {
            this.showDialogDetail = false;
            this.showDialogDelete = false;
            this.isLoading = false;
        }, 500);
    }

    async mounted() {
        setTimeout(() => {
            this.getReports();
        }, 1000);
    }
}
