
















import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedProposalDetail from "@/components/Shared/Proposal/Detail.vue";
import SharedProposalInfo from "@/components/Shared/Proposal/Info.vue";
import { toDisplayName } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        SharedProposalDetail,
        SharedProposalInfo,
    },
    metaInfo() {
        const proposal = this.$store.state.proposals.proposal;
        let proposalTitle = "";
        let proposerDisplayName = "";

        if (proposal) {
            proposalTitle = proposal.title;
            proposerDisplayName = toDisplayName(proposal.user);
        }

        const page = "Proposal - ";
        const title = page + proposalTitle + " oleh " + proposerDisplayName;

        return {
            title: title,
        };
    },
})
export default class AppProposalId extends Vue {
    @Prop() proposalId!: string;

    get me() {
        return this.$store.state.users.me;
    }

    get meRole() {
        return this.$store.getters["users/meRole"];
    }

    get userPlan() {
        return this.$store.state.users.userPlan;
    }

    get proposal() {
        return this.$store.state.proposals.proposal;
    }

    get stories() {
        return this.$store.state.stories.stories;
    }

    get isMeAlreadySeenProposal() {
        let isAlreadySeen: boolean = false;

        if (
            this.proposal &&
            this.proposal.seens &&
            this.proposal.seens.length
        ) {
            this.proposal.seens.map((seen: any) => {
                if (seen.user.id === this.me.id) isAlreadySeen = true;
            });
        }

        return isAlreadySeen;
    }

    async mounted() {
        const proposal: any = await this.$store.dispatch(
            "proposals/getOneProposal",
            this.proposalId
        );

        const stories: any[] = await this.$store.dispatch(
            "stories/getProposalStories",
            this.proposalId
        );

        const isCompany: boolean = this.me.role.name === "COMPANY";

        if (proposal && isCompany && !this.isMeAlreadySeenProposal) {
            await this.$store.dispatch("proposals/addSeen", this.proposalId);
        }

        if (this.meRole === "COMPANY") {
            const userId = this.me.id;
            const year = new Date().getFullYear();

            await this.$store.dispatch("users/getUserFund", { userId, year });
        }
    }
}
